import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FunnelService } from '../../../services/funnel/funnel.service';
import { SpinnerService } from '../../../services/core/spinner/spinner.service';
import { FunnelHistoryRequestModel, HistoryFunnelExportRequestModel } from '../../../models/funnel/export-history-funnel-model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-export-funnel-history',
  standalone: true,
  imports: [MatIconModule, CommonModule],
  templateUrl: './export-funnel-history.component.html',
  styleUrl: './export-funnel-history.component.scss'
})
export class ExportFunnelHistoryComponent {

  private readonly snackBar = inject(MatSnackBar);
  readonly dialogRef = inject(MatDialogRef<ExportFunnelHistoryComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);
  public imgPath = './../../../../assets/images/icons/';
  public deliveries!: {name: string, link: string}[];

  constructor(private readonly funnelService: FunnelService,
      private readonly spinnerService: SpinnerService) {}

  ngOnInit(): void {
    this.getHistory();
  }

  getHistory(): void {
    this.spinnerService.show();
    let request: HistoryFunnelExportRequestModel = { funnel_id:  this.data._id || '', customer_name: '' };

    this.funnelService.getFunnelHistoryExport(request).subscribe({
      next: (response: any) => {
        this.spinnerService.hide();
        this.deliveries = response.data;
      },
      error: (err) => {
        this.spinnerService.hide();
        console.error('Error getting history export:', err);
        this.snackBar.open(`Error getting history export: ${err.message}`, 'Error', { duration: 5000 });
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
