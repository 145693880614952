import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FunnelService } from '../../../services/funnel/funnel.service';
import { ExportFunnelRequestModel } from '../../../models/funnel/export-funnel-request-model';
import { SpinnerService } from '../../../services/core/spinner/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RangeSliderComponent } from "../range-slider/range-slider.component";
import { RegularSliderComponent } from "../regular-slider/regular-slider.component";
import { ExportFunnelResponseModel } from '../../../models/funnel/export-funnel-response-model';


@Component({
  selector: 'app-export-funnel',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, MatIconModule, ReactiveFormsModule, RangeSliderComponent, RegularSliderComponent],
  templateUrl: './export-funnel.component.html',
  styleUrl: './export-funnel.component.scss'
})
export class ExportFunnelComponent {
  private readonly snackBar = inject(MatSnackBar);
  readonly dialogRef = inject(MatDialogRef<ExportFunnelComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);
  public imgPath = './../../../../assets/images/icons/';
  public exportedFunnel!: ExportFunnelResponseModel;
  sampleSize: number = 1000;

  constructor(private readonly funnelService: FunnelService,
    private readonly spinnerService: SpinnerService) {}

  ngOnInit(): void { }

  export(): void {
    this.spinnerService.show();
    let request: ExportFunnelRequestModel = {
      funnel_id:  this.data._id || '',
      customer_name: '',
      max_sample_size: this.sampleSize,
      delivery_type: 'excel'
    };
    this.funnelService.exportFunnel(request).subscribe({
      next: (response: ExportFunnelResponseModel) => {
        this.spinnerService.hide();
        this.exportedFunnel = response;
        window.open(response.sheetLink);
      },
      error: (err) => {
        this.spinnerService.hide();
        console.error('Error downloading Leads:', err);
        this.snackBar.open(`Nothing to export`, 'Message', { duration: 5000 });
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  setSampleSize(event: any): void {
    const newSampleSize = parseInt(event.value);
    if (!isNaN(newSampleSize)) {
      this.sampleSize = newSampleSize;
    }
  }
}
