import { Component, computed, Signal, HostListener, OnInit } from '@angular/core';
import { AuthStatusService } from '../../../services/auth/auth-status.service';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ExitDialogComponent } from '../../custom/exit-dialog/exit-dialog.component';
import Clarity from '@microsoft/clarity';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  isLoggedIn:Signal<boolean> = computed(() => this.authStatusService.isLoggedIn());
  // Only to have a way to tell that we don't support the credit usage yet
  totalCredits: number = -1;
  usedCredits: number = -1;

  constructor(private authStatusService: AuthStatusService, private readonly dialog: MatDialog, private readonly router: Router) {}

  openExitDialog(event: MouseEvent, targetUrl: string): void {
    if (window.location.pathname === '/funnel-management') {
      event.preventDefault();

      const dialogRef = this.dialog.open(ExitDialogComponent);

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.router.navigateByUrl(targetUrl);
        } else {
          return;
        }
      });
    } else {
      this.router.navigateByUrl(targetUrl);
    }
  }

  ngOnInit(): void {
    Clarity.init(environment.CLARITY_PROJECT_ID);
  }
}
