import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { FunnelComponent } from "../../../custom/funnel/funnel.component";
import { FilterModel, FunnelItemModel, FunnelModel } from '../../../../models/funnel-model';
import { CommonModule, NgClass } from '@angular/common';
import { SpinnerService } from '../../../../services/core/spinner/spinner.service';
import { FunnelService } from '../../../../services/funnel/funnel.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MetaService } from '../../../../services/meta/meta.service';
import { RangeSliderComponent } from "../../../custom/range-slider/range-slider.component";
import { ItemModel } from '../../../../models/custom/tree-view-model';
import { TreeViewComponent } from "../../../custom/tree-view/tree-view.component";
import { HintlySelectComponent } from '../../../custom/hintly-select/hintly-select.component';
import { CriteriaPositionComponent } from "../../../custom/criteria-position/criteria-position.component";
import { FunnelFormSorting } from '../../../../models/funnel-management-models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { getShortName, SHORT_NAME } from '../../../../models/constants/short-name';
import { TriStateCheckboxComponent } from '../../../custom/tri-state-checkbox/tri-state-checkbox.component';
import { RangeSliderEvent } from '../../../../models/range-slider-event';

@Component({
  selector: 'app-funnel-management',
  standalone: true,
  imports: [CommonModule, FunnelComponent, FormsModule,
    ReactiveFormsModule, MatCheckboxModule, RangeSliderComponent,
    TreeViewComponent, HintlySelectComponent, CriteriaPositionComponent,
    TriStateCheckboxComponent,
    RouterModule, NgClass
  ],
  templateUrl: './funnel-management.component.html',
  styleUrl: './funnel-management.component.scss'
})
export class FunnelManagementComponent implements OnInit {

  funnelId: any;
  funnelForm!: FormGroup;
  industries!: ItemModel[];
  subIndustries!: ItemModel[];
  platforms!: ItemModel[];
  socialMedia!: ItemModel[];
  ads!: ItemModel[];
  emailSystemList!: ItemModel[];
  crmSystemList!: ItemModel[];
  ecommerceCmsList!: ItemModel[];
  isEcommerceList: ItemModel[] = [];
  hasMarketingDepartmentList: ItemModel[] = [];
  hasAnalyticsPlatformList: ItemModel[] = [];
  hasWebsiteList: ItemModel[] = [];
  companyTypeList!: ItemModel[];
  numberOfCriterias: number = 5;
  standardDescription: string[] = [];
  public sortingValues: WritableSignal<FunnelFormSorting> = signal({
    is_ecommerce: null,
    has_marketing_department: null,
    has_analytics_platform: null,
    platform: null,
  });
  public availablePositions: WritableSignal<{ index: number, available: boolean }[]> = signal([
    { index: 1, available: false },
    { index: 2, available: true },
    { index: 3, available: false },
    { index: 4, available: false },
    { index: 5, available: false }
  ]);

  lastYear: number = new Date().getFullYear();
  viewMode: boolean = false;
  loading: boolean = true;

  constructor(
    public spinnerService: SpinnerService,
    private funnelService: FunnelService,
    private metaService: MetaService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder) {
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.updateSortingValues = this.updateSortingValues.bind(this);
  }
  private snackBar = inject(MatSnackBar);
  funnel!: FunnelModel;
  savedAdvancedCriterias: FunnelItemModel[] = [];

  ngOnInit(): void {
    this.loadFunnel();
    this.loadFilters();
    this.setupSortingValues();
  }

  setupForm() {
    this.funnelForm = this.fb.group({
      name: [{value: '', disabled: this.viewMode}, Validators.required],
      is_favorite: false,
      industry: [[], Validators.required],
      industry_advanced: [[]],
      sub_industry_advanced: [[]],
      gross_results: [''],
      geography: [''],
      number_of_employees: [''],
      is_ecommerce: [[]],
      has_marketing_department: [[]],
      has_analytics_platform: [[]],
      has_website: [[]],
      ads: [[]],
      social_media: [[]],
      email_system: [[]],
      crm_system: [[]],
      ecommerceCms: [[]],
      company_type: [[]],
      incorporation_date: [''],
      no_unique_visitors_avg_3mo: [''],
      number_of_products: [''],
      sorting: {
        sub_industry_advanced: null,
        is_ecommerce: null,
        has_marketing_department: null,
        has_analytics_platform: null,
        has_website: null,
        ads: null,
        social_media: null,
        email_system: null,
        crm_system: null,
        ecommerceCms: null,
        company_type: null,
        incorporation_date: null,
        no_unique_visitors_avg_3mo: null
      }
    });
  }

  async loadFunnel(): Promise<void> {
    this.funnelId = this.route.snapshot.paramMap.get('id');
    if (this.funnelId) {
      this.spinnerService.show();
      this.viewMode = true;
      try {
        this.setupForm();
        const data = await firstValueFrom(this.funnelService.getFunnel(this.funnelId));
        this.funnel = data;
        this.updateFormWithFunnelData();
      }
      catch (error) {
        console.error('Error loading funnel:', error);
      }
      finally {
        this.spinnerService.hide();
        this.loading = false;
      }
    }
    else {
      this.loading = false;
      this.setupForm();
      this.funnel = {
        name: '',
        is_favorite: false,
        criterias: [{
          feature: 'standard',
          description: '',
          counter: 0,
          position: 1,
          filters: []
        }],
        countQuery: null
      };
    }
  }

  updateFormWithFunnelData() {
    if (this.funnel) {
      this.funnelForm.patchValue({  name: this.funnel.name });
      this.setForm();
    }
  }

  setForm() {
    if (this.funnel.criterias) {
      this.funnel.criterias.forEach(criteria => {
        criteria.filters.forEach(filter => {
          this.funnelForm.patchValue({ [filter.name]: filter.values });
          this.setSorting(criteria.position, filter.name);
        });
      });
    }
  }

  loadFilters() {
    this.getIndustryFilter();
    this.getAdvancedIndustryFilter();
    this.getAdsFilter();
    this.getSocialMediaFilter();
    this.getEmailSystemFilter();
    this.getCrmSystemFilter();
    this.getEcommerceCmsFilter();
    this.getCompanyTypeFilter();
    this.getIsEcommerceFilter();
    this.getHasMarketingDepartmentFilter();
    this.getHasAnalyticsPlatformFilter();
    this.getHasWebsiteFilter();
  }

  setupSortingValues() {
    const sorting = this.funnelForm.get('sorting')?.value;
    this.sortingValues.set(sorting);
  }

  getIndustryFilter() {
    this.metaService.getStandardIndustryList().subscribe(data => {
      this.industries = data;
    });
  }

  getAdvancedIndustryFilter() {
    this.metaService.getIndustryList().subscribe(data => {
      this.subIndustries = data;
    });
  }

  getAdsFilter() {
    this.metaService.getAdsList().subscribe(data => {
      this.ads = data;
    });
  }

  getSocialMediaFilter() {
    this.metaService.getSocialMediaList().subscribe(data => {
      this.socialMedia = data;
    });
  }

  getEmailSystemFilter() {
    this.metaService.getEmailSystemList().subscribe(data => {
      this.emailSystemList = data;
    });
  }

  getCrmSystemFilter() {
    this.metaService.getCrmSystemList().subscribe(data => {
      this.crmSystemList = data;
    });
  }

  getEcommerceCmsFilter() {
    this.metaService.getEcommerceCmsList().subscribe(data => {
      this.ecommerceCmsList = data;
    });
  }

  getCompanyTypeFilter() {
    this.metaService.getCompanyTypeList().subscribe(data => {
      this.companyTypeList = data;
    });
  }

  getHasAnalyticsPlatformFilter() {
    this.hasAnalyticsPlatformList.push({ value: 'true', name: 'has_analytics_platform', label: 'Yes'  });
  }

  getHasMarketingDepartmentFilter() {
    this.hasMarketingDepartmentList.push({ value: 'true', name: 'has_marketing_department', label: 'Yes'  });
  }

  getIsEcommerceFilter() {
    this.isEcommerceList.push({ value: 'true', name: 'is_ecommerce', label: 'Yes'  });
  }

  getHasWebsiteFilter() {
    this.hasWebsiteList.push({ value: 'true', name: 'has_website', label: 'Yes'  });
  }

  async updateIndustryCriteria() {
    let shortDesc = getShortName('industry');
    this.addStandardDescription(shortDesc);
    let index = this.getCriteriaByKey('standard');
    this.funnel.criterias[index].description = shortDesc;
    let industries = this.funnelForm.get('industry')!.value as any[];
    const industryNames = industries.map(industry => industry.label);
    let filter = this.getFilterByName(this.funnel.criterias[index], 'industry');
    const filters = this.funnel.criterias[index].filters;
    if (filter == null) {
      filter = { name: 'industry', values: industryNames };
      filters.push(filter);
    } else {
      filter.values = industryNames;
    }
    this.initCriteria(index, 'standard', shortDesc, filters);
    await this.refreshAllCriterias(index);
  }

  async updateAdvancedIndustryCriteria(key: string, models: ItemModel[]) {
    let index = this.getCriteriaByKey(key);
    let additionalMessage = this.getAdditionalMessageForAdvancedIndustry(models);
    let shortDesc = getShortName(key, additionalMessage);
    this.initCriteria(index, key, shortDesc);
    this.initializeSortingValue(key);
    const { values, queries, subValues, subQueries } = this.getValuesAndQueriesForAdvancedIndustry(models);
    this.funnel.criterias[index].filters = [];
    this.funnel.criterias[index].filters.push({ name: key, values: this.funnelForm.get(key)?.value });
    this.funnel.criterias[index].filters.push({ name: 'sub_industry', values: values, queries: queries });
    this.funnel.criterias[index].filters.push({ name: 'sub_industry', values: subValues, queries: subQueries });
    this.updateSavedAdvancedCriterias(key);
    await this.refreshAllCriterias(index);
  }

  async updateMultiValueChecklistCriteria(key: string, models: ItemModel[]) {
    let index = this.getCriteriaByKey(key);
    let additionalMessage = this.getAdditionalMessageForCheckboxList(models);
    let shortDesc = getShortName(key, additionalMessage);
    this.initCriteria(index, key, shortDesc);
    this.initializeSortingValue(key);
    const { values, queries } = this.getValuesAndQueriesForMultiValueCheckboxList(models);
    this.funnel.criterias[index].filters = [];
    this.funnel.criterias[index].filters.push({ name: key, values: values, queries: queries });
    this.updateSavedAdvancedCriterias(key);
    await this.refreshAllCriterias(index);
  }


  private getAdditionalMessageForAdvancedIndustry(models: ItemModel[]): string {
    let additionalMessage = 'Not ';
    models.forEach((model) => {
      if (model.subItems && model.subItems?.length > 0) {
        model.subItems.forEach((subModel) => {
          const allInverse = subModel.subItems?.every((nestedModel) => nestedModel.inverse);
          if (!allInverse) {
            additionalMessage = '';
          }
        });

        const allInverse = model.subItems?.every((subModel) => subModel.inverse);
        if (!allInverse) {
          additionalMessage = '';
        }
      }
    });
    return additionalMessage;
  }

  private getAdditionalMessageForCheckboxList(models: ItemModel[]): string {
    let additionalMessage = '';
    if (models.every(model => model.inverse)) {
      additionalMessage = 'Not ';
    }
    return additionalMessage;
  }

  private getValuesAndQueriesForAdvancedIndustry(models: ItemModel[]): { values: any[], queries: any[], subValues: any[], subQueries: any[] } {
    const values: any[] = [];
    const queries: any[] = [];
    const subValues: any[] = [];
    const subQueries: any[] = [];

    models.forEach(model => {
      this.processModel(model, values, queries, subValues, subQueries);
    });

    return { values, queries, subValues, subQueries };
  }

  private getValuesAndQueriesForMultiValueCheckboxList(models: ItemModel[]): { values: any[], queries: any[] } {
    const values: any[] = [];
    const queries: any[] = [];

    models.forEach(model => {
      this.proccessMultiValueQuery(model, values, queries);
    });

    return { values, queries };
  }

  private processModel(model: ItemModel, values: any[], queries: any[], subValues: any[], subQueries: any[]) {
    if (model.subItems && model.subItems.length > 0) {
      model.subItems.forEach(subModel => {
        this.processSubModel(subModel, values, queries, subValues, subQueries);
      });
    }
  }

  private proccessMultiValueQuery(model: ItemModel, values: any[], queries: any[]) {
    values.push({ name: model.name, value: model.value, inverse: model.inverse });
    queries.push({ name: model.name, value: model.value });
  }

  private processSubModel(subModel: ItemModel, values: any[], queries: any[], subValues: any[], subQueries: any[]) {
    values.push({ name: 'h_Industry2', value: subModel.label, inverse: subModel.inverse });
    queries.push({ name: 'h_Industry2', value: subModel.label });

    if (subModel.subItems && subModel.subItems.length > 0) {
      subModel.subItems.forEach(nestedModel => {
        subValues.push({ name: 'h_Industry3', value: nestedModel.label, inverse: nestedModel.inverse });
        subQueries.push({ name: 'h_Industry3', value: nestedModel.label });
      });
    }
  }

  async updateStandardRange(key: string, min: string, max: string) {
    let shortDesc = getShortName(key);
    this.addStandardDescription(shortDesc);
    let index = this.getCriteriaByKey('standard');
    this.funnel.criterias[index].description = shortDesc;
    let filter = this.getFilterByName(this.funnel.criterias[index], key);
    const values = [min, max];
    const filters = this.funnel.criterias[index].filters;
    if (filter == null) {
      filter = { name: key, values: values };
      filters.push(filter);
    } else {
      filter.values = values;
    }
    this.initCriteria(index, 'standard', shortDesc, filters);
    await this.refreshAllCriterias(index);
  }

  async updateRangeCriteria(key: string, eventValues: RangeSliderEvent) {
    let min: string = eventValues.min == eventValues.minLimit? '' : eventValues.min;
    let max: string = eventValues.max == eventValues.maxLimit? '' : eventValues.max;
    if (key == 'number_of_employees' || key == 'gross_results') {
      return await this.updateStandardRange(key, min, max);
    }
    let index = this.getCriteriaByKey(key);
    let shortDesc = getShortName(key);

    this.initCriteria(index, key, shortDesc);
    let filters = this.funnel.criterias[index].filters;
    if (filters.length == 0) {
      filters.push({ name: key, values: [min, max] });
    }
    filters[0] = { name: key, values: [min, max] };
    this.updateSavedAdvancedCriterias(key);
    await this.refreshAllCriterias(index);
  }

  async updateChecklistCriteria(key: string, models: ItemModel[]) {
    let index = this.getCriteriaByKey(key);
    let additionalMessage = this.getAdditionalMessageForCheckboxList(models);
    let shortDesc = getShortName(key, additionalMessage);
    this.initCriteria(index, key, shortDesc);
    this.initializeSortingValue(key);
    const values: any[] = [];
    const queries: any[] = [];
    for (const model of models) {
      values.push({name: model.name, value : model.value, inverse: model.inverse});
      const queryValue = this.getQueryValue(model, key);
      queries.push({name: model.name, value : queryValue});
    }
    this.funnel.criterias[index].filters = [];
    this.funnel.criterias[index].filters.push({ name: key, values: values, queries: queries });
    this.updateSavedAdvancedCriterias(key);
    await this.refreshAllCriterias(index);
  }

  orderCriteriaByPosition() {
    const sorting = this.funnelForm.get('sorting')?.value;
    this.funnel.criterias.forEach((criteria, index) => {
      criteria.position = sorting[criteria.feature];
    });
    const criteriasSorted = this.funnel.criterias.sort((a, b) => a.position - b.position);
    const criteriasFiltered = criteriasSorted.filter(criteria => criteria.position !== null);
    this.funnel.criterias = criteriasFiltered;
  }

  async refreshAllCriterias(start: number = 0) {
    for (let i = start; i < this.funnel.criterias.length; i++) {
      const { response, query } = this.funnelService.countFunnelMatches(this.funnel.criterias.slice(0, i + 1));
      let count: number = await response.toPromise();
      this.funnel.criterias[i].counter = isNaN(count) ? 0 : count;
      this.funnel.countQuery = query;
    }
  }

  initCriteria(index: number, key: string, description: string, filters: FilterModel[] = []): void {
    let criteriaIndex = index;
    if (key === 'standard') {
      description = this.standardDescription.join(', ');
      criteriaIndex = 0;
    } else if (criteriaIndex === 0) {
      criteriaIndex = 1;
    } else if (criteriaIndex >= 5) {
      criteriaIndex = 5;
    }
    const criteria = {
      feature: key,
      description: description,
      counter: 0,
      position: index + 1,
      filters: filters
    };
    this.funnel.criterias[criteriaIndex] = criteria;
  }

  getCriteriaByKey(key: string): number {
    for (let i = 0; i < this.funnel.criterias.length; i++) {
      if (this.funnel.criterias[i]?.feature == key) {
        return i;
      }
    }
    return this.funnel.criterias.length >= 5 ? 4 : this.funnel.criterias.length;
  }

  getFilterByName(criteria: FunnelItemModel, filterName: string): FilterModel | null {
    for (const filter of criteria.filters) {
      if (filter.name == filterName) {
        return filter;
      }
    }
    return null;
  }

  async sliderValueChange(event: RangeSliderEvent, key: string, isAdvancedFeature?: boolean) {
    this.funnelForm.get(key)?.patchValue(event.min + ':' + event.max);
    if (isAdvancedFeature) {
      this.initializeSortingValue(key);
    }
    await this.updateRangeCriteria(key, event);
  }

  async setCheckboxList(event: ItemModel[], key: string) {
    this.funnelForm.get(key)?.patchValue(event);
    switch (key) {
      case 'industry':
        await this.updateIndustryCriteria();
        break;
      case 'sub_industry_advanced':
        await this.updateAdvancedIndustryCriteria(key, event);
        break;
      case 'company_type':
        await this.updateMultiValueChecklistCriteria(key, event);
        break;
      default:
        await this.updateChecklistCriteria(key, event);
        break;
    }
  }

  getQueryValue(model: ItemModel, key: string): any {
    const existTrueQueryItems = ['has_website', 'social_media'];
    const eqTrueQueryItems = [
      'platform', 'ads', 'ecommerceCms',
      'email_system', 'crm_system',
      'has_marketing_department', 'is_ecommerce',
      'has_analytics_platform'
    ];

    if (model.inverse) {
      if (existTrueQueryItems.includes(key)) return { "$in": [null, 'N/A'] }
      if (eqTrueQueryItems.includes(key)) return { "$ne": true }

      return { '$in': [null, 'N/A', false, undefined] };
    }

    if (existTrueQueryItems.includes(key)) {
      return { '$exists': true, '$ne': 'N/A' };
    }

    if (eqTrueQueryItems.includes(key)) {
      return { '$eq': true }
    }

    return { '$exists': true };
  }


  initializeSortingValue(key: string) {
    const sorting = this.funnelForm.get('sorting')?.value;
    const advancedPositions = [2, 3, 4, 5];
    const nextPositions = advancedPositions.filter(position => !Object.values(sorting).includes(position));
    const nextPosition = nextPositions[0] || 5;
    const range = [];
    for (let i = 2; i <= nextPosition; i++) {
      range.push({
        index: i,
        available: true
      });
    }

    for (let i = nextPosition + 1; i <= 5; i++) {
      range.push({
        index: i,
        available: false
      });
    }

    this.availablePositions.set(range);

    if (sorting[key] === null) {
      sorting[key] = nextPosition;
      this.handlePositionChange(nextPosition, key);
    }
  }

  async save() {
    // TODO - Add proper form validation, for now just mark all fields as touched
    this.spinnerService.show();
    this.funnelForm.markAllAsTouched();
    this.funnel.name = this.funnelForm.get('name')!.value;
    this.setAdvancedFilters();
    const filtersNotSelected: string[] = []

    this.funnel.criterias.forEach((criteria) => {
      const advancedFilters = criteria.filters;
      if ((advancedFilters[0]?.values.length === 0 && advancedFilters[0]?.queries.length === 0) && criteria.feature !== 'standard') {
        this.spinnerService.hide();
        // uppercase first letter of words in criteria.feature
        const formattedFeature = criteria.feature.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        filtersNotSelected.push(`Please choose any values for ${formattedFeature} filter`);
      }


    });

    if (filtersNotSelected.length > 0) {
      this.spinnerService.hide();
      this.snackBar.open(filtersNotSelected.join('\n'));
      return;
    }

    if (this.funnel.criterias.length < 3) {
      this.spinnerService.hide();
      this.snackBar.open("3 to 5 criteria levels are required");
      return;
    }

    if (!this.funnelId) {
      this.funnelService.create(this.funnel).subscribe({
        next: (response) => {
          this.spinnerService.hide();
          this.router.navigate(['/dashboard']);
        },
        error: (error) => {
          this.spinnerService.hide();
          this.snackBar.open("One error occured: " + error.error.data);
        },
      });
    }
  }

  setAdvancedFilters() {
    if (this.funnel.criterias?.[1]) {
      if (!this.funnel.criterias[1]?.filters || this.funnel.criterias[1]?.filters?.length == 0)
        this.funnel.criterias[1].filters = [];

      this.funnel.criterias[1].filters.push({ name: 'industry_advanced', values: this.funnelForm.get('industry')?.value });
    }
  }

  getStandardIndustries(): any {
    const values = this.funnelForm.get('industry')?.value.map((item: { name: any; value: any; }) => ({
      name: item.name,
      value: item.value
    }));
    return values;
  }

  setSorting(index: number, filterName: string) {
    const sorting = this.funnelForm.get('sorting')?.value;
    sorting[filterName] = index;
  }

  async handlePositionChange(index: number, filterName: string) {
    const sorting = this.funnelForm.get('sorting')?.value;
    const oldCriteriaInPosition = this.funnel.criterias.find((criteria) => criteria.position === index);
    this.removeCriteriaInIndex(oldCriteriaInPosition as unknown as FunnelItemModel, index);
    this.insertOrUpdateFunnelItemModelOnPositionChange(index, filterName);
    sorting[filterName] = index;

    Object.entries(sorting).forEach(([key, value]) => {
      if (value === index && key !== filterName) {
        sorting[key] = null;
      }

      if (value) {
        const criteria = this.funnel.criterias.find((criteria) => criteria.feature === key);
        if (criteria) {
          (criteria as unknown as FunnelItemModel).position = value as number;
          this.removeCriteriaInIndex(criteria as unknown as FunnelItemModel, value as number);
          this.funnel.criterias.splice(value as number - 1, 0, criteria);
        }
      }
    });

    this.funnelForm.patchValue({ sorting });
    this.updateSortingValues(this.funnelForm.get('sorting')?.value);
    await this.refreshAllCriterias();
  }

  updateSortingValues(newSortingValues: any) {
    this.sortingValues.set(newSortingValues);
  }

  allCriteriasFilled(): boolean {
    return this.funnel.criterias.length === 5;
  }

  updateSavedAdvancedCriterias(key: string) {
    if (key === 'standard') {
      return;
    }
    const savedCriteria = this.savedAdvancedCriterias.find((criteria) => criteria.feature === key);
    if (savedCriteria) {
      const savedCriteriaIndex = this.savedAdvancedCriterias.indexOf(savedCriteria);
      const funnelCriteria = this.funnel.criterias.find((criteria) => criteria.feature === key);
      if (funnelCriteria) {
        this.savedAdvancedCriterias[savedCriteriaIndex] = funnelCriteria;
      }
    } else {
      const funnelCriteria = this.funnel.criterias.find((criteria) => criteria.feature === key);
      if (funnelCriteria) {
        this.savedAdvancedCriterias.push(funnelCriteria);
      }
    }
  }

  removeCriteriaInIndex(criteria: FunnelItemModel, index: number) {
    if (criteria) {
      const oldIndex = this.funnel.criterias.indexOf(criteria);
      this.funnel.criterias.splice(oldIndex, 1);
    }
  }

  insertOrUpdateFunnelItemModelOnPositionChange(index: number, feature: string) {
    const funnelCriteria = this.funnel.criterias.find((criteria) => criteria.feature === feature);
    if (!funnelCriteria) {
      const savedCriteria = this.savedAdvancedCriterias.find((criteria) => criteria.feature === feature);
      if (savedCriteria) {
        savedCriteria.position = index;
        this.funnel.criterias.splice(index - 1, 0, savedCriteria);
      } else {
        this.funnel.criterias.splice(index - 1, 0, {
          feature: feature,
          description: getShortName(feature),
          counter: 0,
          position: index,
          filters: []
        });
      }
    } else if ((funnelCriteria as unknown as FunnelItemModel).position !== index) {
        (funnelCriteria as unknown as FunnelItemModel).position = index;
    }
  }

  addStandardDescription(shortDesc: string) {
    if (!this.standardDescription.includes(shortDesc))
      this.standardDescription.push(shortDesc);
  }

  getAdditionalMessage(models: ItemModel[]) {
    if (models?.length < 2) {
      if (models[0]?.inverse)
        return 'Not ';
    }
    return '';
  }
}
