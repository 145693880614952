<div *ngIf="isLoggedIn()" class="header-wrapper w-100 bg-white">
  <div class="header-content d-flex justify-content-between align-items-center">
    <div class="logo-wrapper">
      <a href="#" (click)="openExitDialog($event, '/dashboard')">
        <img src="../../../../assets/images/icons/hintly-logo.png" alt="hintly-logo" referrerpolicy="no-referrer"/>
      </a>
    </div>
    <div class="d-flex">
      <p class="credits" *ngIf="totalCredits > 0 && usedCredits > 0">Credits usage: <span class="usage">300 / 10.000</span></p>
    </div>
  </div>
</div>
